<template>
    <b-container id="confirmar-conta" class="height-full" fluid>
        <b-row style="padding-top: 50px">
            <b-col class="text-center">
                <b-img-lazy style="height: 76px"
                            alt="ZZVendas" fluid
                            :src=" require( '../../assets/logos/zzvendas-banner-white.png') "
                ></b-img-lazy>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="text-center">
                <h1 style="padding-top: 50px">
                    OBRIGADO PELA CONFIRMAÇÃO
                </h1>
                <p>
                    Nossos técnicos estão atuando para fazer a <br/>
                    liberação do seu cadastro! Iremos lhe avisar por e-mail <br/>
                    assim que estiver disponível o seu acesso!<br/>
                    <br/>
                    Enquanto isso, você pode navegar pelas nossas <br/>
                    marcas e conhecer cada detalhe dos produtos<br/>
                    que irá oferecer!
                </p>
                <div style="padding-top: 30px">

                    <a href="https://www.anacapri.com.br/" target="_blank">
                        <b-img-lazy style="height: 40px"
                                    alt="ZZVendas" fluid
                                    :src=" require( '../../assets/logos/anacapri-branco.png') "

                        ></b-img-lazy>
                    </a>

                    <a href="https://www.arezzo.com.br/" target="_blank">
                        <b-img-lazy style="height: 30px"
                                    alt="ZZVendas" fluid
                                    :src=" require( '../../assets/logos/arezzo-branco.png') "
                        ></b-img-lazy>
                    </a>

                </div>
            </b-col>
        </b-row>
        <b-row class="rodape">
            <b-col class="text-center">

                <!-- Logo -->
                <div>
                    <b-img-lazy style="height: 50px"
                                alt="ZZVendas" fluid
                                :src=" require( '../../assets/logos/arezzoco-branco.png') "
                    ></b-img-lazy>
                </div>

                <!-- Social Media -->
                <div>
                    <b-img-lazy style="height: 20px"
                                alt="Messenger" fluid
                                :src=" require( '../../assets/logos/messenger-branco.png') "
                    ></b-img-lazy>
                    <b-img-lazy style="height: 20px; padding-left: 10px; padding-right: 10px"
                                alt="Instagram" fluid
                                :src=" require( '../../assets/logos/instagram-branco.png') "
                    ></b-img-lazy>
                    <b-img-lazy style="height: 20px"
                                alt="Youtube" fluid
                                :src=" require( '../../assets/logos/youtube-branco.png') "
                    ></b-img-lazy>
                </div>

                <!-- Rodapé -->
                <div style="padding-top: 30px">
                    <b-img-lazy style="height: 20px"
                                alt="ZZVendas" fluid
                                :src=" require( '../../assets/logos/anacapri-branco.png') "
                    ></b-img-lazy>
                    <b-img-lazy style="height: 15px"
                                alt="ZZVendas" fluid
                                :src=" require( '../../assets/logos/arezzo-branco.png') "
                    ></b-img-lazy>
                    <p>Todos os direitos reservados Arezzo&Co</p>
                </div>

            </b-col>
        </b-row>
    </b-container>
</template>
<style lang="scss">
#confirmar-conta
{
    display: grid;

    *
    {
        font-family: "Montserrat", Arial, Helvetica, sans-serif !important;
        color: white;
    }

    h1
    {
        font-size: 33px;
    }

    p
    {
        font-size: 18px;
    }

}
</style>
<script>

import store from '@/store';
import {Utils} from "../../components/utils";

export default {
    name: 'confirmar-conta',
    mixins: [Utils],
    props: ['ChaveConfirmacao', 'Email'],
    data() {
        return {
            status: 'Confirmando dados...'
        }
    },
    methods: {
        confirmUser() {

            //
            const payload = {
                ChaveConfirmacao: this.ChaveConfirmacao,
                Email: this.Email,
            };

            // Confirmar registro
            store.dispatch('GetConfirmEmail', payload)
                .then((json) => {
                    this.status = json.Message;
                })
                .finally(() => {
                    document.title = 'ZZVendas';
                });
        },
    },
    mounted() {
        document.title = 'Confirmando dados...';
        document.getElementsByTagName('body')[0].style.backgroundColor = '#1EBCD5';

        // Disparar email para confirmar registro
        this.confirmUser();
    }
}
</script>
